html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
@font-face {
  font-family: "Praise";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/praise@latest/latin-400-normal.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.praise {
  font-family: "Praise", cursive;
}
.overflow-hidden {
  overflow-y: hidden;
}
.modal {
  padding-left: 0;
  z-index: 10001 !important;
}

@media only screen and (max-width: 450px) {
  #contunuebutton button.btn-submit {
    padding: 7px 20px;
  }
  .backBtnDiv button.btn-submit {
    padding: 7px 20px;
  }
}
